<template>
<div class="container">
  
  <transition name="fade" appear mode="out-in">
    <component :is="CurrentComponent[position]"
  @StoreQuestion="StoreQuestion"
  :Question="Question"
  @goTo="goTo"
  :answer="answer"
  @ShowResult="getAnswer"
  @startOver="startOver"
  @decideAgain="decideAgain"
  />
  </transition>
</div>
</template>

<script>
import Ask from './components/Ask.vue'
import ShowQuestion from './components/ShowQuestion.vue'
import Result from './components/Result.vue'
export default {
  name: 'App',
  components:{Ask,ShowQuestion,Result},
  data(){
    return{
      list: [
        "Yes",
        "No",
        "Maybe",
        "Not sure..try again",
        "Ask a friend",
        "Call the police",
      ],
      CurrentComponent:['Ask','ShowQuestion','Result'],
      position:0,
      Question:'',
      answer:''
    }
  },
  methods:{
    goTo(P){
      this.position=P
    },
    StoreQuestion(Q){
      this.Question=Q
    },
    randA(){
      return this.list[Math.floor(Math.random()*this.list.length)]
    },
    randomAnswer(){
      let rAnswer=this.randA()
        if(this.answer !== ''){
          while(rAnswer === this.answer){
          rAnswer= this.randA()
        }
        }
     this.answer= rAnswer
    },
    getAnswer(){
      this.randomAnswer()
    },
    startOver(){
      this.position=0
      this.Question=''
      this.answer=''
    },
    decideAgain(){
      this.getAnswer()
    }
  }
}
</script>

<style>
@import url(./assets/style.css);

.fade-enter-from {
  opacity: 0;
}
.fade-enter-active {
  transition: 0.5s;
}
.fade-enter-to {
  opacity: 1;
}
.fade-leave-from {
  opacity: 1;
}
.fade-leave-active {
  transition: 0.5s;
}
.fade-leave-to {
  opacity: 0;
}
</style>