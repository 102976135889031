<template>
  <div>
    <h1>Ask A Question</h1>
  <input type="text" class="form-control" v-model="Question">
  <div class="error" v-if="error">{{error}}</div>
  <button class="btn animate__animated animate__fadeIn animate__delay-1s" @click="Next" v-if="Question">Next</button>
  </div>
</template>

<script>
// import { ref } from '@vue/reactivity'
export default {
    data(){
        return{
            Question:'',
            error:''
        }
    },
    methods:{
        Next(e){
        e.preventDefault()
        if(!this.Question){
            this.error="Kindly ask something"
        }else if(this.Question.length <5){
            this.error='Too short Question'
        }else{
            this.error=""
            this.$emit('StoreQuestion',this.Question)
            this.$emit('goTo',1)
        }
        }
    },
// setup(props,{emit}){
//     const Question = ref('')
//     const error = ref('')
//     const Next =(e)=>{
//         e.preventDefault()
//         if(!Question.value){
//             error.value="Kindly ask something"
//         }else if(Question.value.length <5){
//             error.value='Too short Question'
//         }else{
//             error.value=""
//             emit('StoreQuestion',Question.value)
//             emit('goTo',1)
//         }
//     }
//     return {Question,error,Next}
// }
}
</script>

<style>

</style>