<template>
   <div>
    <h3>Your question is:</h3>
    <div class="viewer"> {{ Question }} </div>
    <button class="btn" @click="goNext"> Decide it </button>
    <button class="btn" @click="goBack"> Ask a new question </button>
   </div>
</template>

<script>
export default {
    props:['Question'],
    methods:{
         goNext(){
        this.$emit("ShowResult")
        this.$emit("goTo",2)
    },
    goBack(){
        this.$emit("goTo",0)
    }
    },
/*setup(props,{emit}){
    const goNext=()=>{
        emit("ShowResult")
        emit("goTo",2)
    }
    const goBack=()=>{
        emit("goTo",0)
    }

    return {goNext,goBack}
}*/
}
</script>

<style>

</style>