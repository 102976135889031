<template>
   <div>
    <h3>Your answer is:</h3>
    <div class="viewer"> {{ answer }} </div>
    <button class="btn" @click="goNext"> Start Over </button>
    <button class="btn" @click="goBack"> Decide again </button>
   </div>
</template>

<script>
export default {
    props:['answer'],
    methods:{
        goNext(){
        this.$emit("startOver")
    },
    goBack(){
        this.$emit('decideAgain')
    }
    },
// setup(props,{emit}){
//     const goNext=()=>{
//         emit("startOver")
//     }
//     const goBack=()=>{
//         emit('decideAgain')
//     }

//     return {goNext,goBack}
// }
}
</script>

<style>

</style>